// Here we can turn on/off items which are displayed at navbar and modal tabs by changing 'display' key

export const userSettings = {
    navbar: [
        {
            display: true,
            label: "In pipeline",
            path: "/pipeline"
        },
        {
            display: true,
            label: "Published articles",
            path: "/published"
        },
        {
            display: true,
            label: "Institutions/funders",
            path: "/institutions&funders"
        },
        {
            display: true,
            label: "Deals",
            path: "/deals"
        },
        {
            display: false,
            label: "Workflow",
            path: "/workflow"
        },
    ],
    details: [
        {
            display: true,
            label: "Metadata",
        },
        {
            display: true,
            label: "JSON",
        },
        {
            display: true,
            label: "Events",
        },
        {
            display: true,
            label: "Messages",
        },
        {
            display: false,
            label: "Affiliations",
        },
    ],
};