import React from "react";
import { getInstitutionDetails } from "../../helpers/institutions";
import { shortenTitle } from "pubtrack-frontend-library";
import {
    CustomTable
} from "pubtrack-frontend-library";


const DealDetails = (props) => {
    const getDataTable = data => {
        if (data) {
            return data.map(item => {
                const title = item.title ? shortenTitle(item.title, 100) : '';
                const journal = item.journal ? shortenTitle(item.journal, 100) : '';
                return {
                    title: title,
                    journal: journal,
                    doi: item.doi,
                    prioragreement: item.prioragreement,
                };
            });
        }
        return [];
    };

    const getInstitutionDetailsData = () => {
        if (props.match.params.id) {
            const rorId = decodeURIComponent(props.match.params.id);
            return getInstitutionDetails(rorId)
                .then(res => ({data: getDataTable(res.data)}))
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Title',
                accessor: 'title',
                sortable: false
            },
            {
                Header: 'Journal',
                accessor: 'journal',
                sortable: false
            },
        ], []
    );


    return (
        <CustomTable
            columns={columns}
            getTableData={getInstitutionDetailsData}
            search={false}
        />
    );
};


export default DealDetails;